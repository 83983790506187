import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

// Elements
import BannerDetail from 'components/elements/Misc/BannerDetail'
import Share from 'components/elements/Misc/Share'
import Video from 'components/elements/Misc/Video'

const ShareWrapper = styled.div`
  position: relative;
  @media (min-width: 992px) {
    margin-top: -17.5rem;
    margin-bottom: 10rem;
  }
  @media (max-width: 991px) {
    margin-bottom: 3rem;
  }
`

const ImageWrapper = styled(motion.div)`
  overflow: hidden;
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 550px;
  }
  @media (max-width: 991px) {
    height: 300px;
  }
  @media (max-width: 767px) {
    height: auto;
  }
  @media (max-width: 575px) {
    height: 225px;
  }
`

interface PageProps {
  data: {
    page: {
      title?: string
      projectdetail: any
      path?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
  pageContext: any
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { title, projectdetail, flexContent, seo },
  },
  location = {},
  prefix = 'Project_Flexcontent',
  pageContext,
}) => {
  let modulusIndex = 0

  return (
    <Layout>
      <SEO seo={seo} />
      <BannerDetail
        type="landing"
        image={projectdetail.bannerimage}
        title={title || 'Project'}
        pageContext={pageContext}
        description={projectdetail.description}
      />
      <section className="mb-5 pb-lg-5">
        <div className="container">
          <div className="row justify-content-end">
            <ShareWrapper className="col-lg-4">
              <Share
                heading="Deel dit project"
                location={location}
                subject={title || 'Vacature'}
                body={projectdetail.description}
              />
            </ShareWrapper>
          </div>
          {projectdetail.video && <Video video={projectdetail.video} />}
          <SimpleReactLightbox>
            <SRLWrapper>
              <div className="row">
                {projectdetail.gallery.map((image: any, index: number) => {
                  const [isHover, setHover] = useState<boolean>(false)

                  let size = 'm'

                  if (index !== 0 && index % 2 === 0) {
                    modulusIndex = modulusIndex === 0 ? 1 : 0
                  }

                  if (index % 2 === modulusIndex) {
                    size = 's'
                  }

                  return (
                    <div className={`col-md-${size === 's' ? '4' : '8'} mb-4`}>
                      <ImageWrapper
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        role="button"
                        onHoverStart={() => setHover(true)}
                        onHoverEnd={() => setHover(false)}
                      >
                        <motion.div
                          initial={{ scale: 1 }}
                          animate={isHover ? { scale: 1.1 } : { scale: 1.0 }}
                          exit={{ scale: 1 }}
                          transition={{ duration: 0.25 }}
                        >
                          <Image image={image} alt="" />
                        </motion.div>
                      </ImageWrapper>
                    </div>
                  )
                })}
              </div>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
      </section>
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query projectById($pageId: String!) {
    page: wpProject(id: { eq: $pageId }) {
      ...generalProjectFragment
    }
  }
`

export default PageTemplate
